<template>
    <v-card class="mb-0 " :width="width" outlined>
        <v-list-item three-line>
            <div>
                <files-icon
                    class="py-1"
                    :fileType="file.extenstion.toLowerCase()"
                    :width="iconWidth"
                    :showBadge="false"
                ></files-icon>
            </div>
            <v-list-item-content class="px-1">
                <v-row>
                    <v-col cols="12" md="12">
                        <div :title="file.name" class="mb-0 text-wrap">
                            {{ file.name | truncate(20) }}
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-list-item-subtitle>
                            {{ file.extenstion.toUpperCase() }}
                            <span v-if="fileSizeFormat"
                                >({{ fileSizeFormat }})</span
                            >
                        </v-list-item-subtitle>
                    </v-col>
                    <v-col cols="12" md="3" class="pa-1">
                        <v-btn
                            outlined
                            rounded
                            text
                            x-small
                            color="blue"
                            @click="downLoadFile(file.path)"
                        >
                            <v-icon small>mdi-download</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col v-if="isInRole(85)" cols="12" md="3" class="pa-1">
                        <v-btn
                            outlined
                            @click="deleteFile"
                            rounded
                            text
                            color="red"
                            x-small
                        >
                            <v-icon small>mdi-delete-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>
<script>
import filesIcon from "./filesIcon.vue";
export default {
    components: { filesIcon },
    props: {
        fileUrl: {
            type: String
        },
        file: {
            type: Object
        },
        width: {
            type: Number,
            default: 300
        },
        fileNameLength: {
            type: Number,
            default: 25
        },
        fileSizeFormat: {
            type: String,
            default: ""
        },
        iconWidth: {
            type: Number,
            default: 64
        },
        onDelete: {
            type: Function
        }
    },
    methods: {
        downLoadFile(url) {
            window.open(url, "_blank");
        },
        deleteFile() {
            if (this.onDelete) {
                this.onDelete(this.file.nameWithDirectory);
            }
        }
    }
};
</script>
<style scoped>
.v-application .text-wrap {
    white-space: normal !important;
    overflow-wrap: anywhere;
}
.v-card__actions {
    padding-top: 0;
}
</style>
